import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, navigateTo } from 'gatsby'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/Drawer'
import Typograpy from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { DRAWER_WIDTH } from '../../constants/ui'
import { PROJECT } from '../../constants/project'
import Menu from './Menu'
import Search from '../Search'

const useStyles = makeStyles(theme =>
  createStyles({
    drawer: {
      width: DRAWER_WIDTH,
    },
    drawerPaper: {
      boxSizing: 'border-box',
      width: DRAWER_WIDTH,
      padding: theme.spacing(2),

      [theme.breakpoints.down('sm')]: {
        width: DRAWER_WIDTH,
      },
    },
    title: {
      '&:hover': {
        color: theme.palette.primary.main,
      },

      cursor: 'pointer',
    },
    link:{
      cursor:'pointer',
      textDecoration:'none'
    },
    linkText:{
      color: theme.palette.primary.main,

    }
  })
)

const Drawer = ({ open, setDrawerOpen }) => {
  const classes = useStyles()

  const handleDrawerClose = () => setDrawerOpen(false)

  const matches = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const data = useStaticQuery(graphql`
    query DrawerStrapiSectionQuery {
      allStrapiSection{
        edges {
          node {
            Order
            id
            Title
            Articles {
              id
              Title
              RouteName
              Project
            }
          }
        }
      }
      allStrapiProject {
        edges {
          node {
            id
            Name
            Site
            SiteTitle
          }
        }
      }
    }
  `)

  const handleClickTitle = () => {
    navigateTo('/')

    setDrawerOpen(false)

    localStorage.removeItem('expandedSection')
    localStorage.removeItem('selectedMenuItem')
  }

  const project = data.allStrapiProject.edges.filter(({ node }) => node.Name === PROJECT)[0]

  return (
    <SwipeableDrawer
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      variant={matches ? 'temporary' : 'permanent'}
      anchor="left"
      onClose={handleDrawerClose}
      open={open}
    >
      <Grid container={true} direction="column" spacing={2}>
        <Grid item={true}>
          <Typograpy
            className={classes.title}
            variant="h6"
            onClick={handleClickTitle}
          >
            Справочный центр
          </Typograpy>
        </Grid>
        <Grid item={true}>
          <a href={project.node.Site} className={classes.link}><Typograpy  className={classes.linkText}>{project.node.SiteTitle}</Typograpy></a>
        </Grid>
        <Grid container={true} item={true}>
          <Search />
        </Grid>
        <Grid item={true}>
          <Menu
            onClick={handleDrawerClose}
            items={data.allStrapiSection.edges
              .filter(({ node }) => node.Articles.filter(value => project.node.id.endsWith(value.Project)).length > 0)
              .sort((a,b) => {
                return a.node.Order - b.node.Order;

              })
              .map(({ node }) => ({
                id: node.id,
                title: node.Title,
                items: node.Articles.filter(value => project.node.id.endsWith(value.Project)).map(value => ({
                  title: value.Title,
                  id: value.id,
                  routeName: value.RouteName,
                })),
              }))}
          />
        </Grid>
      </Grid>
    </SwipeableDrawer>
  )
}

Drawer.propTypes = {
  open: PropTypes.bool,
  setDrawerOpen: PropTypes.func,
}

export default Drawer
