import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  createStyles,
  ThemeProvider,
} from '@material-ui/core/styles'
import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import 'typeface-roboto'

import { DRAWER_WIDTH } from '../../constants/ui'
import { theme } from '../../theme'
import Header from './Header'
import Drawer from './Drawer'
import './index.css'

const useStyles = makeStyles(theme =>
  createStyles({
    articleHeader: {
      [theme.breakpoints.down(1440)]: {
        padding: theme.spacing(20, 20, 25),
      },

      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(20, 10, 25),
      },

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(15, 5),
      },

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(10, 3),
      },

      alignItems: 'center',
      backgroundColor: '#f5f5f5',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(15, 30, 22),
    },
    article: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      position: 'relative',
      width: `calc(100% - ${DRAWER_WIDTH}px)`,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    container: {
      boxSizing: 'border-box',
      display: 'flex',
      height: '100%',
      width: '100%',
    },
    footer: {
      // backgroundColor: '#000',
      padding: theme.spacing(1, 0),
    },
    header: {
      height: theme.spacing(6),
      padding: theme.spacing(1, 2),

      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    main: {
      alignItems: 'center',
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      padding: theme.spacing(0, 20),

      [theme.breakpoints.down(1140)]: {
        padding: theme.spacing(0, 15),
      },

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 10),
      },

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2),
      },
    },
  })
)

const Layout = ({ children, header, footer }) => {
  const classes = useStyles()

  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleDrawerOpen = () => setDrawerOpen(true)

  return (
    <ThemeProvider theme={theme}>
      <Header className={classes.header}>
        <IconButton color="primary" onClick={handleDrawerOpen}>
          <MenuIcon />
        </IconButton>
      </Header>
      <div className={classes.container}>
        <Drawer open={drawerOpen} setDrawerOpen={setDrawerOpen} />
        <Fade in={true} timeout={500}>
          <article className={classes.article}>
            <Header className={classes.articleHeader}>{header}</Header>
            <main className={classes.main}>{children}</main>
            <footer className={classes.footer}>{footer}</footer>
          </article>
        </Fade>
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
  footer: PropTypes.node,
}

export default Layout
